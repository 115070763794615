/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages

			    $('#navbar-categories').on('show.bs.collapse', function() {
			        $('#navbar-toggle-icon').removeClass('fa-bars').addClass('fa-times');
			    });
			    $('#navbar-categories').on('hide.bs.collapse', function() {
			        $('#navbar-toggle-icon').addClass('fa-bars').removeClass('fa-times');
			    });

			    $('#filter').on('show.bs.collapse', function() {
			        $('#icon-filter').removeClass('fa-filter').addClass('fa-times');
			    });
			    $('#filter').on('hide.bs.collapse', function() {
			        $('#icon-filter').addClass('fa-filter').removeClass('fa-times');
			    });
				
				$('.carousel').slick({
					dots: false,
					infinite: true,
					autoplay: true,
					autoplaySpeed: 5000,
					slidesToShow: 5,
					slidesToScroll: 1,
					cssEase: 'ease-in-out',
					responsive: [
						{
							breakpoint: 768,
							settings: {
								slidesToShow: 2,
								slidesToScroll: 1,
								infinite: true,
								dots: true,
								arrows: false
							}
						}
					]
				});

				$('.slider').slick({
					arrows: false,
					dots: false,
					infinite: true,
					autoplay: true,
					autoplaySpeed: 5000,
					slidesToShow: 1,
					slidesToScroll: 1,
					cssEase: 'ease-in-out',
					centerMode: true
				});

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
